import React from 'react'
import Headline from '@objects/headline'
import Button from '@objects/button'
import Copy from '@objects/copy'
import TwoColumnText from '@objects/twoColumnText'
import UnorderedList from '@objects/unorderedlist'
import OrderedList from '@objects/orderedlist'
import BlockQuote from '@objects/blockquote'
import Separator from '@objects/separator'
import Image from '@objects/image'
import ImageText from '@objects/imageText'
import YouTube from '@objects/youtube'
import EmbeddedVideo from '@objects/embeddedVideo'
import FaqAccordion from '@objects/faqAccordion'
import GalleryCarousel from '@objects/galleryCarousel'
import InfoBox from '@objects/infoBox'
import SingleDialogPost from '@objects/singleDialogPost'
import TeaserBubble from '@objects/teaserBubble'
import TeaserBlock from '@objects/teaserBlock'

function renderTextPart(content) {
  const regex = /\.(html|pdf|jpg|jpeg|png|webp|gif)$/gm

  return content?.map((c, i) => {
    if (c.nodeType === 'text' && c.value?.trim().length) {
      return c.marks?.length
        ? React.createElement(c.marks[0].type[0], { key: i }, c.value)
        : c.value
    } else if (c.nodeType === 'hyperlink') {
      return React.createElement(
        Button,
        {
          key: i,
          type: 'text',
          link: c.data.uri,
          // link: `${c.data.uri}${
          //   c.data.uri.charAt(c.data.uri.length - 1) === '/' ||
          //   c.data.uri.match(regex)
          //     ? ''
          //     : '/'
          // }`,
          target: c.data.uri.includes('https://www.deutschland-spricht-ueber-5g.de')
          ? '_self'
          : '_blank',
        },
        c.content[0].value
      )
    } else if (c.nodeType === 'list-item') {
      return React.createElement(
        'ul',
        { key: i },
        c.content.map((item, i) => {
          return React.createElement(
            'li',
            { key: i },
            item.content.map((subItem, j) => {
              if (subItem.nodeType === 'text') {
                return subItem.value
              } else if (subItem.nodeType === 'hyperlink') {
                return React.createElement(
                  Button,
                  {
                    key: i,
                    type: 'text',
                    link: `${subItem.data.uri}${
                      subItem.data.uri.charAt(subItem.data.uri.length - 1) === '/' ||
                      subItem.data.uri.match(regex)
                        ? ''
                        : '/'
                    }`,
                    target: subItem.data.uri.includes('https://www.deutschland-spricht-ueber-5g.de')
                      ? '_self'
                      : '_blank',
                  },
                  subItem.content[0].value
                )
              } else {
                return null
              }
            })
          )
        })
      )
    } else {
      return null
    }
  })
}

function createFluidImageSrc(image) {
  if (!image?.details?.image) return null
  return {
    aspectRatio: image.details.image.width / image.details.image.height,
    src: image.url + '?w=800&q=90',
    srcSet: `
      ${image.url}?w=${Math.round(
      image.details.image.width / 4
    )}&&q=90 ${Math.round(image.details.image.width / 4)}w,
      ${image.url}?w=${Math.round(
      image.details.image.width / 2
    )}&&q=90 ${Math.round(image.details.image.width / 2)}w,
      ${image.url}?w=${image.details.image.width}&&q=90 ${
      image.details.image.width
    }w,
      ${image.url}?w=${Math.round(
      image.details.image.width * 1.5
    )}&&q=90 ${Math.round(image.details.image.width * 1.5)}w,
      ${image.url}?w=1000&&q=90 1000w
    `,
    sizes: '(max-width: 800px) 100vw, 800px',
  }
}

function createTocList(articleContent, locale) {
  return articleContent
    ?.filter((component) => {
      const cmp =
        component.nodeType === 'embedded-entry-block'
          ? component.data.target?.sys?.contentType?.sys?.id
          : component.nodeType
      return ['heading-2', 'imageText', 'faqCategory'].includes(cmp)
    })
    .map((component, i) => {
      const props = findParser(component).props(component, locale)
      return {
        id: props.id,
        label: props.headline || (props.children ? props.children[0] : ''),
      }
    })
}

function findParser(component) {
  return ComponentParser.find((cmp) => {
    if (component.nodeType === 'embedded-entry-block') {
      return component.data.target?.sys?.contentType?.sys?.id.match(
        cmp.nodeType
      )
    } else {
      return component.nodeType.match(cmp.nodeType)
    }
  })
}

const ComponentParser = [
  {
    nodeType: 'paragraph',
    component: Copy,
    props: (component) => {
      return {
        children: renderTextPart(component.content),
        parseGlossary: true,
      }
    },
  },
  {
    nodeType: 'twoColumnText',
    component: TwoColumnText,
    props: (component, locale) => {
      return {
        id: component.data.target.fields.title?.[locale]
          ?.replace(/\s+/g, '')
          .toLowerCase(),
        copy: {
          left: renderTextPart(
            component.data.target.fields.leftText?.[locale]?.content[0].content
          ),
          right: renderTextPart(
            component.data.target.fields.rightText?.[locale]?.content[0].content
          ),
        },
      }
    },
  },
  {
    nodeType: 'unordered-list',
    component: UnorderedList,
    props: (component) => {
      return {
        children: component.content.map((ct) => {
          return renderTextPart(ct.content[0].content)
        }),
        parseGlossary: true,
      }
    },
  },
  {
    nodeType: 'ordered-list',
    component: OrderedList,
    props: (component) => {
      return {
        children: component.content.map((ct) => {
          return renderTextPart(ct.content[0].content)
        }),
        parseGlossary: true,
      }
    },
  },
  {
    nodeType: 'heading-',
    component: Headline,
    props: (component) => {
      return {
        level: parseInt(
          component.nodeType.substring(component.nodeType.length - 1)
        ),
        id: component.content[0].value.replace(/\s+/g, '').toLowerCase(),
        children: component.content.map((ct) => ct.value),
      }
    },
  },
  {
    nodeType: 'hr',
    component: Separator,
    props: () => {
      return {}
    },
  },
  {
    nodeType: 'blockquote',
    component: BlockQuote,
    props: (component, locale, sidebar, faq) => {
      return {
        text: component.content[0]?.content[0]?.value,
        author: component.content[1]?.content[0]?.value,
        sidebar: sidebar ? true : null,
        faq: faq,
      }
    },
  },
  {
    nodeType: 'image$',
    component: Image,
    props: (component, locale) => {
      return {
        image: component.data.target.fields.image
          ? createFluidImageSrc(
              component.data.target.fields.image?.de?.fields?.file?.[locale]
            )
          : null,
        alt: component.data.target.fields.image?.de?.fields?.description?.[
          locale
        ],
        description: component.data.target.fields.description?.[locale],
        copyright: component.data.target.fields.copyright?.[locale],
        rightBorder: component.data.target.fields.rightBorder?.[locale],
      }
    },
  },
  {
    nodeType: 'imageText',
    component: ImageText,
    props: (component, locale) => {
      const content =
        component.data.target.fields.copy?.[locale]?.content?.map((item) =>
          renderTextPart(item.content)
        ) || []

      return {
        headline: component.data.target.fields.headline?.[locale],
        id: component.data.target.fields.headline?.[locale]
          ?.replace(/\s+/g, '')
          .toLowerCase(),
        direction: component.data.target.fields.direction,
        copy: content,
        image: {
          fluid: component.data.target.fields.media?.de?.fields?.image
            ? createFluidImageSrc(
                component.data.target.fields.media?.de?.fields?.image?.de
                  ?.fields?.file?.[locale]
              )
            : null,
          alt: component.data.target.fields.media?.de?.fields?.image?.de?.fields
            ?.description?.[locale],
          copyright:
            component.data.target.fields.media?.[locale]?.fields?.copyright?.[
              locale
            ],
        },
      }
    },
  },
  {
    nodeType: 'youtube',
    component: YouTube,
    props: (component, locale) => {
      return {
        ytid: component.data.target.fields?.youTubeKey?.[locale],
        title: component.data.target.fields?.title?.[locale],
        copyright: component.data.target.fields?.copyright?.[locale],
        description: component.data.target.fields?.description?.[locale],
        thumbnail: component.data.target.fields?.thumbnail
          ? createFluidImageSrc(
              component.data.target.fields.thumbnail?.de.fields.file?.[locale]
            )
          : null,
        alt: component.data.target.fields?.thumbnail?.[locale]?.fields
          ?.description?.[locale],
      }
    },
  },
  {
    nodeType: 'video',
    component: EmbeddedVideo,
    props: (component, locale) => {
      const fields = component?.data?.target?.fields

      return {
        id: fields?.id,
        video: fields?.media?.[locale]?.fields?.file?.[locale]?.url,
        copyright: fields?.copyright?.[locale],
        description: fields?.description?.[locale],
        thumbnail: createFluidImageSrc(
          fields?.thumbnail?.de?.fields?.file?.[locale]
        ),
        alt: fields?.thumbnail?.[locale]?.fields?.description?.[locale],
        boxCopy: fields?.boxCopy?.[locale],
        title: fields?.title?.[locale],
      }
    },
  },
  {
    nodeType: 'faqCategory',
    component: FaqAccordion,
    props: (component, locale) => {
      const {
        data: {
          target: {
            fields: {
              title: { [locale]: categoryTitle = '' },
              faQs: { de: categoryFaQs },
            },
          },
        },
      } = component

      const parsedCategoryFaQs = categoryFaQs
        .filter((cat) => !!cat.fields.question?.[locale])

        .map((cat) => {
          return {
            id: cat.sys.id,
            question: cat.fields.question?.[locale],
            answer: cat.fields.answer?.[locale].content,
          }
        })

      return {
        headline: categoryTitle,
        id: categoryTitle.replace(/\s+/g, '').toLowerCase(),
        faqs: parsedCategoryFaQs,
      }
    },
  },
  {
    nodeType: 'galleryCarousel',
    component: GalleryCarousel,
    props: (component, locale, sidebar) => {
      const headline = component.data.target.fields.title?.[locale]
      const images = component.data.target.fields.images?.de

      const parsedItems = images
        ?.filter((image) => !!image.fields?.image?.de?.fields?.file?.[locale])
        .map((image) => {
          return {
            id: image.sys.contentful_id,
            image: image.fields.image
              ? createFluidImageSrc(
                  image.fields.image?.de?.fields?.file?.[locale]
                )
              : null,
            alt: image.fields.image?.de?.fields?.description?.[locale],
            description: image.fields.description?.[locale],
            copyright: image.fields.copyright?.[locale],
          }
        })

      return {
        headline: headline,
        items: parsedItems,
        sidebar: sidebar ? true : null,
      }
    },
  },
  {
    nodeType: 'infoBox',
    component: InfoBox,
    props: (component, locale) => {
      const content =
        component.data.target.fields.copy?.[locale]?.content?.map((item) =>
          renderTextPart(item.content)
        ) || []

      return {
        headline: component.data.target.fields.title?.[locale],
        copy: content,
      }
    },
  },
  {
    nodeType: 'teaserBlock',
    component: TeaserBlock,
    props: (component, locale) => {
      return {
        headline: component.data.target.fields.headline[locale],
        label: component.data.target.fields.label[locale],
        target: component.data.target.fields.target[locale],
        isExternal: component.data.target.fields.isExternal[locale],
      }
    },
  },
  {
    nodeType: 'singleDialogPost',
    component: SingleDialogPost,
    props: (component, locale) => {
      return {
        postId: component.data.target.fields.postId[locale],
      }
    },
  },
  {
    nodeType: 'teaserBubble',
    component: TeaserBubble,
    props: (component, locale) => {
      return {
        title: component.data.target.fields.title[locale],
      }
    },
  },
]

export { createFluidImageSrc, createTocList, findParser }

export default ComponentParser
